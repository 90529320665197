<!-- 登录页 -->
<template>
  <div class="login">
    <div style="
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 60px;
        padding-top: 30px;
      ">
      <div>
        <div style="text-align:right">
          <img src="../assets/电 话@2x.png" style="width: 16px; height: 16px" alt />
          客服：400-183-1833
          <div>17332917092</div>
          <div>17332917091</div>
        </div>
        <div style="margin-top:10px;">
          业务咨询：15633598882
        </div>
      </div>
    </div>
    <div style="
        font-size: 50px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin-top: 30px;
        text-align: center;
      ">函驿通电子保函服务平台</div>
    <div class="vessel">
      <div class="leftvessel">
        <div class="leftbutton" style="margin-top: 20px" @click="operationManual">操 作 手 册</div>
        <!-- <div class="leftbutton">
          <a href="javascript:void(0)" onclick="window.open('http://online.aheca.cn/ocss/')">C A 申 请</a>
        </div>
        <div class="leftbutton">
          <a
            href="http://www.aheca.cn/service.jsp?strColId=1304674236906003#currentpart"
            target="_blank"
          >CA助手下载</a>
        </div>
        <div class="leftbutton" @click="download">CFCA助手下载</div> -->
      </div>
      <div style="flex: 1" ref="login">
        <div style="
            display: flex;
            justify-content: center;
            width: 500px;
            margin: 0 auto;
            margin-top: 43px;
            border-bottom: 1px solid #e6e6e6;
          ">
          <div style="
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #666666;
              width: 176px;
              text-align: center;
              display: flex;
              flex-direction: column;
              cursor: pointer;
            " @click="CAaccount">
            <div :class="{ account: account }">账号登录</div>
            <img src="../assets/矩形 2 拷贝 2@2x.png" style="width: 100%; height: 10px" v-if="this.account" alt />
          </div>
          <!-- <div
            style="
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #666666;
              width: 176px;
              text-align: center;
              display: flex;
              flex-direction: column;
              cursor: pointer;
            "
            @click="CAregister"
          >
            <div :class="{ CAlogin: CAlogin }">CA登录</div>
            <img
              src="../assets/矩形 2 拷贝 2@2x.png"
              style="width: 100%; height: 10px"
              v-if="this.CAlogin"
              alt
            />
          </div>
          <div
            style="
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #666666;
              width: 176px;
              text-align: center;
              display: flex;
              flex-direction: column;
              cursor: pointer;
            "
            @click="CFCAregister"
          >
            <div :class="{ CFCAlogin: CFCAlogin }">CFCA登录</div>
            <img
              src="../assets/矩形 2 拷贝 2@2x.png"
              style="width: 100%; height: 10px"
              v-if="this.CFCAlogin"
              alt
            />
          </div> -->
        </div>
        <div style="
            width: 500px;
            margin: 0 auto;
            margin-top: 96px;
            position: relative;
          " v-if="this.account">
          <div style="position: absolute; top: -50px; right: 0">
            <el-button type="text" style="color: #039eee" @click="authCode">
              {{
                this.secret ? "切换验证码登录" : "切换密码登录"
              }}
            </el-button>
          </div>
          <el-form :model="accountForm" :rules="accountRules" ref="accountForm" label-width="80px" class="demo-ruleForm"
            key="account" v-if="this.secret">
            <el-form-item label="账号" prop="account">
              <el-input v-model="accountForm.account" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="accountForm.password" show-password placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center; margin-top: 40px; margin-right: 80px;">
              <el-button style="font-size: 18px" type="primary"
                @click="submitForm('accountForm')">&nbsp;&nbsp;&nbsp;登&nbsp;&nbsp;&nbsp;&nbsp;录&nbsp;&nbsp;&nbsp;</el-button>
            </el-form-item>
            <el-form-item style="text-align: center; margin-right: 80px;">
              <el-button type="text" style="color: #666666; font-family: SimHei; font-weight: 400"
                @click="forget">忘记密码</el-button>
              <el-button type="text" style="color: #666666; font-family: SimHei; font-weight: 400"
                @click="enroll">立即注册</el-button>
            </el-form-item>
          </el-form>
          <el-form :model="phoneForm" :rules="phoneRules" ref="phoneForm" label-width="80px" class="demo-ruleForm"
            key="phone" v-if="this.secret == false">
            <el-form-item label="手机号" prop="phone">
              <span class="befor_1">*</span>
              <el-input v-model="phoneForm.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="password">
              <el-input v-model="phoneForm.password" placeholder="请输入短信验证码" style="width: 73%"></el-input>
              <el-button type="primary" style="margin-left: 15px; width: 98px" @click="sendMsg" :disabled="isDisabled1">{{
                code1 }}</el-button>
            </el-form-item>
            <el-form-item style="text-align: center; margin-top: 40px">
              <el-button style="font-size: 18px" type="primary"
                @click="phoneLogin('phoneForm')">&nbsp;&nbsp;&nbsp;登&nbsp;&nbsp;&nbsp;&nbsp;录&nbsp;&nbsp;&nbsp;</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div style="width: 500px; margin: 0 auto; margin-top: 96px" v-if="this.CAlogin">
          <el-form :model="CAform" :rules="CArules" ref="CAform" class="demo-ruleForm" @submit.native.prevent
            label-width="80px" key="CAform">
            <el-form-item label="密码：" prop="password">
              <el-input v-model="CAform.password" show-password placeholder="请输入密码" style="width: 100%"
                @keyup.enter.native="CAenter('CAform')"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center; margin-top: 92px">
              <el-button type="primary" style="font-size: 18px" @click="CAenter('CAform')"
                :loading="isShow">&nbsp;&nbsp;&nbsp;登&nbsp;&nbsp;&nbsp;&nbsp;录&nbsp;&nbsp;&nbsp;</el-button>
            </el-form-item>
            <el-form-item style="
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                margin-top: 38px;
              ">请确保您的电脑上已正确插入Ukey</el-form-item>
          </el-form>
        </div>
        <div style="width: 500px; margin: 0 auto; margin-top: 96px" v-if="this.CFCAlogin">
          <el-form :model="CFCAform" :rules="CFCArules" ref="CFCAform" class="demo-ruleForm" @submit.native.prevent
            label-width="80px" key="CFCAform">
            <el-form-item label="密码：" prop="password">
              <el-input v-model="CFCAform.password" show-password placeholder="请输入密码" style="width: 100%"
                @keyup.enter.native="CFCAenter('CFCAform')"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center; margin-top: 92px">
              <el-button type="primary" style="font-size: 18px" @click="CFCAenter('CFCAform')"
                :loading="isShow">&nbsp;&nbsp;&nbsp;登&nbsp;&nbsp;&nbsp;&nbsp;录&nbsp;&nbsp;&nbsp;</el-button>
            </el-form-item>
            <el-form-item style="
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                margin-top: 38px;
              ">请确保您的电脑上已正确插入Ukey</el-form-item>
          </el-form>
        </div>
      </div>
      <div style="flex: 1; width: 500px; margin: 0 auto" ref="retrieve">
        <div class="topTitle">
          <div class="account" style="width: 166px; text-align: center">找回密码</div>
          <img src="../assets/矩形 2 拷贝 2@2x.png" style="width: 166px; height: 10px" alt />
        </div>
        <div style="width: 500px; margin: 20px auto 0px">
          <div style="text-align: right">
            <el-button type="text" style="color: #039eee" @click="authentication">
              {{
                this.barter ? "企业认证找回密码" : "账号验证找回密码"
              }}
            </el-button>
          </div>
          <el-form :model="seekForm" :rules="seekRules" ref="seekForm" label-width="100px" class="demo-seekForm"
            v-if="barter">
            <el-form-item label="账号" prop="account">
              <el-input v-model="seekForm.account" placeholder="7~15位，支持汉字、字母、数字格式"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
              <el-input show-password v-model="seekForm.password" placeholder="6~20位，包含大、小写字母，数字至少各1个"></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="affirm">
              <span class="befor_2">*</span>
              <el-input show-password v-model="seekForm.affirm" placeholder="6~20位，包含大、小写字母，数字至少各1个"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <span class="befor_3">*</span>
              <el-input v-model="seekForm.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input style="width: 70%" v-model="seekForm.code" placeholder="请输入短信验证码"></el-input>
              <el-button type="primary" style="margin-left: 20px; width: 98px" @click="sendMsgTwo"
                :disabled="isDisabled2">{{ code2 }}</el-button>
            </el-form-item>
            <el-form-item style="text-align: center; margin-right: 80px;">
              <el-button type="primary" @click="submitseek('seekForm')">确认</el-button>
              <el-button @click="resetForm('seekForm')">取消</el-button>
            </el-form-item>
          </el-form>
          <div v-if="this.barter == false">
            <div style="position: relative;">
              &nbsp;&nbsp;请扫描下方二维码咨询客服：
              <span class="befor_4">*</span>
            </div>
            <div style="text-align: center;">
              <img src="../assets/二维码@2x.png" style="widht: 200px; height: 200px; margin-top: 50px" alt />
            </div>
          </div>
        </div>
      </div>
      <div style="flex: 1; width: 500px; margin: 0 auto" ref="enroll">
        <div class="topTitle">
          <div class="account" style="width: 166px; text-align: center">账号注册</div>
          <img src="../assets/矩形 2 拷贝 2@2x.png" style="width: 166px; height: 10px" alt />
        </div>
        <el-form :model="enrollForm" :rules="enrollRules" ref="enrollForm" label-width="100px" class="demo-enrollForm"
          style="width: 500px; margin: 30px auto 0px">
          <el-form-item label="账号" prop="account">
            <el-input v-model="enrollForm.account" placeholder="7~15位，支持汉字、字母、数字格式"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input show-password v-model="enrollForm.password" placeholder="6~20位，包含大、小写字母，数字至少各1个"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="affirm">
            <span class="befor_2">*</span>
            <el-input show-password v-model="enrollForm.affirm" placeholder="6~20位，包含大、小写字母，数字至少各1个"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <span class="befor_3">*</span>
            <el-input v-model="enrollForm.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input style="width: 70%" v-model="enrollForm.code" placeholder="请输入短信验证码"></el-input>
            <el-button type="primary" style="margin-left: 20px; width: 98px" @click="sendMsgThree"
              :disabled="isDisabled3">{{ code3 }}</el-button>
          </el-form-item>
          <div style="text-align: center">
            <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
            <el-button type="text" style="padding: 0" @click="userServiceAgreement">《用户服务协议》</el-button>
          </div>
          <el-form-item style="text-align: center; margin: 19px 100px 0 0">
            <el-button type="primary" @click="submitenroll('enrollForm')" :disabled="!checked">注册</el-button>
          </el-form-item>
          <div style="
              text-align: center;
              font-size: 14px;
              font-family: SimHei;
              font-weight: 400;
              color: #666666;
              margin-top: 10px;
            ">
            如有账号，
            <el-button type="text" style="padding: 0" @click="immediately">立即登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div style="margin-top:10px;color:#fff;text-align:center;font-size:14px;">
      <el-link :underline="false" href="https://beian.miit.gov.cn" target="_blank"
        style="color:#fff">冀ICP备19022994号</el-link>
    </div>
    <el-dialog title="用户服务协议" :visible.sync="serviceAgreement" width="60%" top="7vh" center>
      <!-- <iframe src="https://www.suzhou.haneton.com/protocol.pdf" frameborder="0"
        style="width: 100%;height: 650px;"></iframe> -->
      <div class="content">
        <p>
          欢迎“您”（以下或称“用户”）阅读“函驿通”（以下称“本公司”）服务协议（以下称“本协议”），本协议阐述之条款和条件适用于您使用平台（所涉域名为www.haneton.com，下同）所提供的在全国企业保函交易、保函查验和各项服务(下称“服务”)。
        </p>
        <p> 1、接受条款</p>
        <p>
          请仔细阅读下面的协议，只有接受协议才能进行注册。注册成功即表示您已充分阅读、理解并同意接受本协议的条款和条件。本公司有权根据业务需要酌情修订“条款”，并在网站上予以公告。经修订的“条款”一经在本平台公布，即产生效力。如您继续使用“服务”，则将视为您已接受经修订的“条款”，当您与本公司发生争议时，应以最新的“条款”为准。
          本服务协议内容包括协议正文及所有已发布的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。</p>
        <p> 2、服务使用对象</p>
        <p>
          “服务”仅供能够根据中国相关法律订立具有法律约束力的合约的公司使用。因此，用户需为依据中国法律设立的法人或非法人主体且具备独立履约能力，用户在平台的操作人员年龄必须在十八周岁或以上且具有完全行为能力，才可使用本服务。如使用“服务”，则视为其自认满足本项条件。企业注册用户完成CA等认证手续后，方能通过平台委托担保公司签订电子合同和开具保函。待企业注册用户与个人用户关联后，即认定企业授权个人用户在平台进行保函交易、查询等服务的权限。
        </p>
        <p>
          3.收费</p>
        <p>
          本公司保留在根据第1条通知您后，收取“服务”费用的权利。您因进行交易、向本公司获取有偿服务或接触本公司服务器而发生的所有应纳税赋，以及相关硬件、软件、通讯、网络服务及其他方面的费用均由您自行承担。本公司保留在无须发出书面通知，仅在本平台公示的情况下，暂时或永久地更改或停止部分或全部“服务”的权利。
        </p>
        <p> 4. 您的资料和交易的产品</p>
        <p>
          “您的资料”包括您在注册、交易或列举产品过程中、在任何公开信息场合或通过任何电子邮件形式，向本公司或其他用户提供的任何资料，包括数据、文本、软件、音乐、声响、照片、图画、影像、词句或其他材料。您应对“您的资料”负全部责任，而本公司仅作为您在网上发布和刊登“您的资料”的被动渠道。但是，倘若本公司认为“您的资料”可能使本公司承担任何法律或道义上的责任，或可能使本公司(全部或部分地)失去本公司的互联网服务供应商或其他供应商的服务，或您未在本公司规定的期限内登录或再次登录网站，则本公司可自行全权决定对“您的资料”采取本公司认为必要或适当的任何行动，包括但不限于删除该类资料。您应保证您对提交给本公司的“您的资料”拥有全部权利，包括全部版权。您确认，本公司没有责任去认定或决定您提交给本公司的资料哪些是应当受到保护的，对享有“服务”的其他用户使用“您的资料”，本公司也不必负责。注册用户如违反本协议造成本平台信誉和经济损失的，
          公司有权追究其经济与法律责任，并根据情况取消其用户资格。</p>
        <p> 5、注册义务</p>
        <p> 根据本平台刊载的资料表格的要求，提供关于您的真实、准确、完整和反映当前情况的资料，并对资料的真实性和合法性承担法律责任。
          6、会员注册名、密码保密</p>
        <p>
          一旦注册成功成为本平台用户，您将得到一个密码和帐号。如果您不保管好自己的帐号和密码安全，将对因此产生的后果负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。您可随时根据指示改变您的密码。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，立即通知本平台。如丢失密码应及时通知本平台进行更改。
        </p>
        <p>
          7、用户责任</p>
        <p> 用户单独承担传输内容的责任。用户必须遵循：</p>
        <p> （1）.不会有欺诈成份，与售卖伪造或盗窃无涉；</p>
        <p>
          （2）.不会侵犯任何第三者对该产品享有的物权，或版权、专利、商标、商业秘密或其他知识产权，或隐私权、名誉权；</p>
        <p>
          （3）.不会违反任何法律、法规、条例或规章(包括但不限于关于保护消费者、不正当竞争或虚假广告的法律、法规、条例或规章)；</p>
        <p> （4）.不会含有诽谤（包括商业诽谤）、非法恐吓或非法骚扰的内容；</p>
        <p>
          （5）.不会含有淫秽、或包含任何儿童色情内容；</p>
        <p> （6）.不会含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序；</p>
        <p> （7）.
          可能使本公司违反任何相关法律、法规、条例或规章的任何产品信息；</p>
        <p> （8）. 本公司认为应禁止或不适合通过本网站交易的任何产品信息。</p>
        <p> 8、保函备案</p>
        <p>
          出具保函的服务机构对其在本平台备案的保函的真实性、准确性、完整性负责，因保函内容的真实性、准确性和完整性引起的法律纠纷由出具保函的服务机构自行承担，本平台作为保函查询服务平台对此不承担任何责任。 </p>
        <p>9、免责条款</p>
        <p>
          鉴于不能预见或预防所有网络环境的变化和技术上可能出现的问题，这些变化和问题可能导致数据丢失或服务的中断。用户应对此表示理解，并承担使用本网站、产品所提供的服务的所有风险和后果。同时，用户应对从本网站下载或通过本网站、产品所提供服务而获得的任何信息承担所有风险和责任。
        </p>
        <p>10、诉讼</p>
        <p> 因本协议或本公司服务所引起或与其有关的任何争议应向 公司住所地人民法院提起诉讼，并以中华人民共和国法律为管辖法律。 11、解释权
          本注册协议的解释权归说明书之家所有。如果其中有任何条款与国家的有关法律相抵触，则以国家法律的明文规定为准。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="serviceAgreement = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <upBox></upBox> -->
    <div class="happy">
      <el-dialog title="" :visible.sync="userVisible" :show-close="false" width="1280px">
        <div style="width: 1280px; height: 566px;position: relative;">
          <img style="width: 100%;,height: 100%;" src="../assets/Dragon Boat Festival.png" alt="">
          <div @click="userVisible = false"
            style="width: 50px;height: 50px;position: absolute;right: 0;top: 0;opacity: 0;cursor: pointer;"></div>
        </div>
      </el-dialog>
    </div>
    <el-dialog title="2024年劳动节放假通知" :visible.sync="centerDialogVisible" width="38%" center>
      <div style="font-size:18px;">
        <p>尊敬的用户：</p>
        <p style="text-indent: 2em">平台根据国家节假日放假规定，结合公司实际情况，研究决定：</p>
        <p style="text-indent: 2em">公司放假安排：2024年5月01日--5月05日</p>
        <p style="text-indent: 2em">正式上班时间：5月06日</p>
        <p style="text-indent: 2em;color:#ff0000">放假期间如需咨询可留言客服，客服看到后将在上班后第一时间回复您，由此带来的不便，敬请谅解！</p>
        <p style="text-align:right;margin-top:10px;">河北筑正科技有限公司</p>
        <p style="text-align:right;"> 2024年04月23日</p>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
    <el-dialog :visible.sync="centerShow" width="600px" center top="340px" :close-on-press-escape="false" :show-close="false" :close-on-click-modal="false">
      <div style="font-size:20px;text-align:center">
        <p style="font-weight:bold"><i class="el-icon-warning" style="font-size:24px;margin-right:5px;color:#e6a23c"></i>重要提醒</p>
        <p>系统升级中，请勿申请。</p>
      </div>
      <!-- <div style="margin-top:30px;text-align:center">
        <el-button type="primary" @click="centerShow = false">&nbsp;&nbsp;&nbsp;确&nbsp;&nbsp;定&nbsp;&nbsp;&nbsp;</el-button>
      </div> -->
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里导入其他文件（组件，工具js，第三方插件js，json文件，图片文件等等）
//import 《组件名称》 from '《组件路径》';
import downloadjs from "downloadjs";
// import upBox from "./upBox.vue"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},

  // 组件名称
  name: "",

  // 这里存放数据
  data() {
    const phoneRules = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      } else {
        const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };

    const validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.seekForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.enrollForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      centerShow:true,
      userVisible: false,
      centerDialogVisible: false,
      isShow: false,
      CAform: {
        password: "",
      },
      CArules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      CFCAform: {
        password: "",
      },
      CFCArules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      account: true,
      CAlogin: false,
      CFCAlogin: false,
      dialogVisible: false,
      accountForm: {
        account: "",
        password: "",
      },
      accountRules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      secret: true,
      phoneForm: {
        phone: "",
        password: "",
      },
      phoneRules: {
        phone: [{ validator: phoneRules, trigger: "blur" }],
        password: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      code1: "立即获取",
      code2: "立即获取",
      code3: "立即获取",
      isDisabled1: false,
      isDisabled2: false,
      isDisabled3: false,
      time: 59,
      seekForm: {
        account: "",
        password: "",
        affirm: "",
        phone: "",
        code: "",
      },
      seekRules: {
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { min: 7, max: 15, message: "7~15位，支持汉字、字母、数字格式" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "6~20位，包含大、小写字母，数字至少各1个",
          },
          {
            pattern:
              /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9]{6,20}$/,
            message: "6~20位，包含大、小写字母，数字至少各1个",
            trigger: "blur",
          },
        ],
        affirm: [{ validator: validatePass1, trigger: "blur" }],
        phone: [{ validator: phoneRules, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      barter: true,
      enrollForm: {
        account: "",
        password: "",
        affirm: "",
        phone: "",
        code: "",
      },
      enrollRules: {
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { min: 7, max: 15, message: "7~15位，支持汉字、字母、数字格式" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "6~20位，包含大、小写字母，数字至少各1个",
          },
          {
            pattern:
              /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9]{6,20}$/,
            message: "6~20位，包含大、小写字母，数字至少各1个",
            trigger: "blur",
          },
        ],
        affirm: [{ validator: validatePass2, trigger: "blur" }],
        phone: [{ validator: phoneRules, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      checked: false,
      serviceAgreement: false,
    };
  },

  // 监听属性 类似于data概念
  computed: {},

  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    //
    authCode() {
      this.secret = !this.secret;
      this.accountForm.account = "";
      this.accountForm.password = "";
      this.phoneForm.phone = "";
      this.phoneForm.password = "";
    },

    sendMsg() {
      this.$refs.phoneForm.validateField("phone", (errorMsg) => {
        if (!errorMsg) {
          this.axios({
            method: "POST",
            url: "/registerController.do?getHandsetRandCode",
            data: {
              handsetNum: this.phoneForm.phone,
              isCheck: 1,
              fromType: 10,
            },
          }).then((res) => {
            if (res.data.status == 0) {
              let me = this;
              me.isDisabled1 = true;
              let interval = window.setInterval(function () {
                me.code1 = me.time;
                --me.time;
                if (me.time < 0) {
                  me.code1 = "重新发送";
                  me.time = 59;
                  me.isDisabled1 = false;
                  window.clearInterval(interval);
                }
              }, 1000);
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
        }
      });
    },

    sendMsgTwo() {
      this.$refs.seekForm.validateField("phone", (errorMsg) => {
        if (!errorMsg) {
          this.axios({
            method: "GET",
            url: "/findPasswordController.do?getSecurityCode",
            params: {
              phone: this.seekForm.phone,
            },
          }).then((res) => {
            if (res.data) {
              let me = this;
              me.isDisabled2 = true;
              let interval = window.setInterval(function () {
                me.code2 = me.time;
                --me.time;
                if (me.time < 0) {
                  me.code2 = "重新发送";
                  me.time = 59;
                  me.isDisabled2 = false;
                  window.clearInterval(interval);
                }
              }, 1000);
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
        }
      });
    },

    sendMsgThree() {
      this.$refs.enrollForm.validateField("phone", (errorMsg) => {
        if (!errorMsg) {
          this.axios({
            method: "GET",
            url: "/loginController.do?checkUsername",
            params: {
              username: this.enrollForm.account,
              acountPhone: this.enrollForm.phone,
              fromType: 10,
            },
          }).then((res) => {
            if (res.data.success) {
              this.axios({
                method: "POST",
                url: "/registerController.do?getHandsetRandCode",
                data: {
                  handsetNum: this.enrollForm.phone,
                },
              }).then((res) => {
                if (res.data) {
                  let me = this;
                  me.isDisabled3 = true;
                  let interval = window.setInterval(function () {
                    me.code3 = me.time;
                    --me.time;
                    if (me.time < 0) {
                      me.code3 = "重新发送";
                      me.time = 59;
                      me.isDisabled3 = false;
                      window.clearInterval(interval);
                    }
                  }, 1000);
                } else {
                  this.$message({
                    message: res.data.statusInfo,
                    type: "error",
                  });
                }
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        } else {
        }
      });
    },

    // 账号密码登录
    CAaccount() {
      this.account = true;
      this.CAlogin = false;
      this.CFCAlogin = false;
      this.isShow = false;
      this.$nextTick(() => {
        this.$refs.accountForm.clearValidate();
        this.$refs.phoneForm.clearValidate();
      });
    },

    // CA登录
    CAregister() {
      this.CAlogin = true;
      this.account = false;
      this.CFCAlogin = false;
      this.isShow = false;
      this.$nextTick(() => {
        this.$refs.CAform.clearValidate();
      });
    },

    // 账号密码登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios({
            method: "POST",
            url: "/loginController.do?usernameLogin",
            data: {
              username: this.accountForm.account,
              password: this.accountForm.password,
              fromType: 10,
            },
          }).then((res) => {
            if (res.data.status == 0) {
              window.sessionStorage.setItem("phone", res.data.data.handsetNum);
              window.sessionStorage.setItem("type", "account");
              window.sessionStorage.setItem("username", res.data.data.username);
              window.sessionStorage.setItem(
                "firmName",
                res.data.data.caukey_id
              );
              this.axios({
                method: "GET",
                url: "/newGuarantee.do?existEnterpriseInfo",
                params: {
                  username: this.accountForm.account,
                  from: 10,
                },
              }).then((res) => {
                if (res.data.status == 0) {
                  if (res.data.data.apply) {
                    this.$message({
                      message: "登录成功",
                      type: "success",
                    });
                    this.$router.push("/main");
                    window.sessionStorage.setItem("menu", "login");
                    window.sessionStorage.setItem("apply1", 1);
                    window.sessionStorage.setItem("apply2", 1);
                    window.sessionStorage.setItem(
                      "com_name",
                      res.data.data.info.com_name
                    );
                    window.sessionStorage.setItem(
                      "socialCode",
                      res.data.data.info.com_code
                    );
                  } else {
                    this.$message({
                      message: "登录成功,请先完善企业信息",
                      type: "success",
                    });
                    this.$router.push("/enterprise");
                    window.sessionStorage.setItem("apply1", 2);
                    window.sessionStorage.setItem("apply2", 2);
                  }
                } else {
                  this.$message({
                    message: res.data.statusInfo,
                    type: "error",
                  });
                }
              });
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    // 手机号登录
    phoneLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios({
            method: "POST",
            url: "/registerController.do?handsetNumLogin",
            data: {
              handsetNum: this.phoneForm.phone,
              randCode: this.phoneForm.password,
              fromType: 10,
            },
          }).then((res) => {
            if (res.data.status == 0) {
              window.sessionStorage.setItem("phone", res.data.data.handsetNum);
              window.sessionStorage.setItem("type", "account");
              window.sessionStorage.setItem("username", res.data.data.username);
              window.sessionStorage.setItem(
                "firmName",
                res.data.data.caukey_id
              );
              this.axios({
                method: "GET",
                url: "/newGuarantee.do?existEnterpriseInfo",
                params: {
                  handsetNum: this.phoneForm.phone,
                  from: 10,
                },
              }).then((res) => {
                if (res.data.status == 0) {
                  if (res.data.data.apply) {
                    this.$message({
                      message: "登录成功",
                      type: "success",
                    });
                    this.$router.push("/main");
                    window.sessionStorage.setItem("apply1", 1);
                    window.sessionStorage.setItem("apply2", 1);
                    window.sessionStorage.setItem(
                      "com_name",
                      res.data.data.info.com_name
                    );
                    window.sessionStorage.setItem(
                      "socialCode",
                      res.data.data.info.com_code
                    );
                  } else {
                    this.$message({
                      message: "登录成功,请先完善企业信息",
                      type: "success",
                    });
                    this.$router.push("/enterprise");
                    window.sessionStorage.setItem("apply1", 2);
                    window.sessionStorage.setItem("apply2", 2);
                  }
                } else {
                  this.$message({
                    message: res.data.statusInfo,
                    type: "error",
                  });
                }
              });
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    // CFCA登录
    CFCAregister() {
      this.account = false;
      this.CAlogin = false;
      this.CFCAlogin = true;
      this.isShow = false;
      this.$nextTick(() => {
        this.$refs.CFCAform.clearValidate();
        this.$confirm("需要获取CFCA证书信息", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            XSGetCertData_Demo();
          })
          .catch(() => { });
      });
    },

    // CA登录
    CAenter(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 成功
          //执行以下代码，根据用户电脑配置不同一般需要1-4秒处理时间，建议加一个加载中样式
          var certPwd = this.CAform.password; //获取页面用户输入的证书密码
          this.isShow = true;
          var signType = "P7"; //签名类型（大写的P1或P7）
          var srcData = "1234567"; //该数据应由服务端返回的随机字符串，类似验证码，每次由服务端随机产生下发到客户端

          try {
            //第1步：调用封装的方法处理请求数据；四个参数分别为登录接口函数、签名原文、证书密码、签名类型
            var json = createParams("SOF_Auth_YB", srcData, certPwd, signType);
            //第2步：调用封装的方法异步请求数据
            AjaxIO_YB(json, function () {
              //第3步：获取请求结果
              var obj = getAjaxResult();
              if (obj != null) {
                //第4步：处理请求结果，业务逻辑要放在这里面处理
                if (obj.retCode == 0) {
                  //成功
                  // console.log("签名结果：" + obj.authdata);
                  // console.log("证书信息：" + obj.certdata);
                  // console.log("KEY序列号：" + obj.keysn);

                  // 解析证书
                  that.certParse(obj.certdata);
                } else {
                  //失败
                  that.$message({
                    message: obj.retMsg,
                    type: "error",
                  });
                  that.isShow = false;
                  return;
                }
              } else {
                //正在进行循环获取数据中，此处无需处理
              }
            });
          } catch (e) {
            //操作异常
            // alert("驱动服务加载异常，请安装或重新打开助手后使用");
          }
        } else {
          return false;
        }
      });
    },

    // 解析证书
    certParse(e) {
      //执行以下代码，根据用户电脑配置不同一般需要1-4秒处理时间，建议加一个加载中样式
      var cert_info = e; //加密后密文

      cert_info = cert_info.replace(/(^\s*)|(\s*$)/g, "");

      try {
        var X509Cert = new X509();
        X509Cert.readCertPEM(cert_info);

        //获取证书指定项信息
        var certCn = X509Cert.getSubjectStringByField("CN"); //证书名称，证书CN项
        var certO = X509Cert.getSubjectStringByField("O"); //证书O项
        var certOU = X509Cert.getSubjectStringByField("OU"); //证书OU项
        var certE = X509Cert.getSubjectStringByField("E"); //证书E项
        var serialNumber = X509Cert.getSerialNumberHex(); //证书序列号，每个证书唯一，延期后可能会发生变化
        var notBefore = X509Cert.getNotBeforeFormat(); //证书有效期开始时间
        var notAfter = X509Cert.getNotAfterFormat(); //证书有效期截止时间
        var date = new Date().getTime();

        // 检查指定企业信息是否存在
        this.axios({
          method: "GET",
          url: "/newGuarantee.do?existEnterpriseInfo",
          params: {
            CAKeyId: certCn,
            from: 10,
            CAKeyEncStr: date,
          },
        }).then((res) => {
          if (res.data.status == 0) {
            if (res.data.data.apply == true) {
              this.$message({
                message: "登录成功",
                type: "success",
              });
              this.isShow = false;
              window.sessionStorage.setItem("firmName", certCn);
              window.sessionStorage.setItem("socialCode", certO);
              window.sessionStorage.setItem("date", date);
              window.sessionStorage.setItem(
                "com_code",
                res.data.data.info.com_code
              );
              window.sessionStorage.setItem(
                "com_address",
                res.data.data.info.com_address
              );
              window.sessionStorage.setItem(
                "legal_person",
                res.data.data.info.legal_person
              );
              window.sessionStorage.setItem(
                "card_code",
                res.data.data.info.card_code
              );
              window.sessionStorage.setItem("apply1", 1);
              window.sessionStorage.setItem("apply2", 1);
              window.sessionStorage.setItem("type", "CA");
              this.$router.push("/main");
            } else {
              this.$message({
                message: "登录成功,请先完善企业信息",
                type: "success",
              });
              this.isShow = false;
              window.sessionStorage.setItem("firmName", certCn);
              window.sessionStorage.setItem("socialCode", certO);
              window.sessionStorage.setItem("date", date);
              window.sessionStorage.setItem("apply1", 2);
              window.sessionStorage.setItem("apply2", 2);
              window.sessionStorage.setItem("type", "CA");
              this.$router.push("/message");
            }
          } else {
            this.isShow = false;
            this.$message({
              message: "登陆失败",
              type: "error",
            });
          }
        });
      } catch (e) {
        this.isShow = false;
      }
    },

    // CFCA登录
    CFCAenter(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (window.sessionStorage.getItem("firmName")) {
            this.isShow = true;
            XSP1Signature_Demo(this.CFCAform.password);
          } else {
            this.$confirm("未获取到CFCA证书信息,是否立即获取", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                XSGetCertData_Demo();
              })
              .catch(() => { });
          }
        } else {
          return false;
        }
      });
    },

    theThird(e) {
      if (e == 1) {
        this.$message({
          message: "获取成功,请进行登录",
          type: "success",
        });
      } else if (e == 2) {
        // 密码错误
        this.$message({
          message: "密码错误",
          type: "error",
        });
        this.isShow = false;
      } else if (e == 3) {
        // CFCA没插
        this.$message({
          message: "未检测到CFCA,请确认插入CFCA",
          type: "warning",
        });
        this.isShow = false;
      } else {
        this.$message({
          message: "接口异常",
          type: "error",
        });
        this.isShow = false;
      }
    },

    finish(e) {
      if (e == 1) {
        var firmName = window.sessionStorage.getItem("firmName");
        var content = window.sessionStorage.getItem("content");
        // 检查指定企业信息是否存在
        this.axios({
          method: "GET",
          url: "/newGuarantee.do?existEnterpriseInfo",
          params: {
            CAKeyId: firmName,
            from: 10,
            CAKeyEncStr: content,
          },
        }).then((res) => {
          if (res.data.data.apply == true) {
            this.$message({
              message: "登录成功",
              type: "success",
            });
            window.sessionStorage.setItem(
              "com_address",
              res.data.data.info.com_address
            );
            window.sessionStorage.setItem(
              "legal_person",
              res.data.data.info.legal_person
            );
            window.sessionStorage.setItem(
              "card_code",
              res.data.data.info.card_code
            );
            window.sessionStorage.setItem("apply1", 1);
            window.sessionStorage.setItem("apply2", 1);
            window.sessionStorage.setItem("type", "CFCA");
            this.$router.push("/main");
          } else {
            this.$message({
              message: "登录成功,请先完善企业信息",
              type: "success",
            });
            window.sessionStorage.setItem("apply1", 2);
            window.sessionStorage.setItem("apply2", 2);
            window.sessionStorage.setItem("type", "CFCA");
            this.$router.push("/message");
          }
        });
      } else if (e == 2) {
        // 密码错误
        this.$message({
          message: "密码错误",
          type: "error",
        });
        this.isShow = false;
      } else if (e == 3) {
        // CFCA没插
        this.$message({
          message: "未检测到CFCA,请确认插入CFCA",
          type: "warning",
        });
        this.isShow = false;
      } else {
        this.$message({
          message: "接口异常",
          type: "error",
        });
        this.isShow = false;
      }
    },

    operationManual() {
      window.open("https://ejlb673kl8.feishu.cn/wiki/wikcnjVAIfeV1fZkcPvyY0lhHGd")
      // var operationManualPdf = "https://www.suzhou.haneton.com/manualsSZ.pdf";
      // downloadjs(operationManualPdf);
    },

    download() {
      window.open(
        "https://www.suzhou.haneton.com/CFCA%E7%AD%BE%E7%AB%A0%E5%B7%A5%E5%85%B7%E5%8C%85.exe"
      );
    },

    forget() {
      this.$refs.login.style.display = "none";
      this.$refs.retrieve.style.display = "block";
    },

    enroll() {
      this.$refs.login.style.display = "none";
      this.$refs.enroll.style.display = "block";
    },

    submitseek(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios({
            method: "POST",
            url: "/findPasswordController.do?findPasswordByUserName",
            data: {
              username: this.seekForm.account,
              newPassword: this.seekForm.affirm,
              acountPhone: this.seekForm.phone,
              securityCode: this.seekForm.code,
              fromType: 10,
            },
          }).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "修改密码成功,请进行登录",
                type: "success",
              });
              this.$refs.login.style.display = "block";
              this.$refs.retrieve.style.display = "none";
              this.seekForm.account = "";
              this.seekForm.password = "";
              this.seekForm.affirm = "";
              this.seekForm.phone = "";
              this.seekForm.code = "";
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$refs.login.style.display = "block";
      this.$refs.retrieve.style.display = "none";
    },

    authentication() {
      this.barter = !this.barter;
    },

    submitenroll(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios({
            method: "POST",
            url: "/registerController.do?userRegister",
            data: {
              username: this.enrollForm.account,
              password: this.enrollForm.password,
              confirmPassword: this.enrollForm.affirm,
              handsetNum: this.enrollForm.phone,
              randCode: this.enrollForm.code,
              fromType: 10,
            },
          }).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "注册成功,请进行登录",
                type: "success",
              });
              this.$refs.enroll.style.display = "none";
              this.$refs.login.style.display = "block";
              this.$refs.enrollForm.resetFields();
              this.checked = false;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    immediately() {
      this.$refs.enroll.style.display = "none";
      this.$refs.login.style.display = "block";
      this.accountForm.account = "";
      this.accountForm.password = "";
    },

    userServiceAgreement() {
      this.serviceAgreement = true;
    },
    endTime() {
      this.userVisible = false;
    },
    getTime() {
      let id = setInterval(() => {
        let data = new Date();
        this.year = data.getFullYear();
        this.month = data.getMonth() + 1; // 获取月
        this.strDate = data.getDate(); // 获取日
        this.newTime = `${this.year}年${this.month}月${this.strDate}日`;
        this.userVisible = true;
        clearInterval(id);
      }, 100);
    },
    bu(a) {
      return a > 9 ? a : "0" + a;
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() { },

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.$alert('因系统升级，出函时间大约为3-5个工作日!', '重要提醒', {
    //   confirmButtonText: '确定',
    //   dangerouslyUseHTMLString: true,
    //   type: 'warning',
    //   center: true,
    //   showClose:false,
    //   callback: action => {
    //   }
    // });
    // if(Date.now()<=1713477600000){
    //   this.$alert('由于系统升级，平台将于2024年4月18日19:00~2024年4月19日06:00进行系统更新维护，在此期间暂停电子保函业务办理，如有需要请您联系平台客服，给您带来不便敬请谅解。</br></br>客服电话：13932119165', '平台公告', {
    //     dangerouslyUseHTMLString: true,
    //     showConfirmButton:false,
    //     showClose:false,
    //     closeOnClickModal:false,
    //     closeOnPressEscape:false,
    //     closeOnHashChange:false,
    //     callback: action => {
    //       this.$message({
    //         type: 'info',
    //         message: `action: ${ action }`
    //       });
    //     }
    //   });
    // }
    let data = new Date().getTime();
    let data2 = new Date("2024-05-06 00:00:00").getTime();
    //是否超过设定时间
    if (data <= data2) {
      this.centerDialogVisible = true
    } else {
      this.centerDialogVisible = false
    }
    // window.location.href = 'https://www.suzhou.haneton.com/'
    // window.firstStep = this.firstStep;
    window.theThird = this.theThird;
    window.finish = this.finish;
    this.$refs.retrieve.style.display = "none";
    this.$refs.enroll.style.display = "none";
  },

  // 生命周期 - 创建之前
  beforeCreate() { },

  // 生命周期 - 挂载之前
  beforeMount() { },

  // 生命周期 - 更新之前
  beforeUpdate() { },

  // 生命周期 - 更新之后
  updated() { },

  // 生命周期 - 销毁之前
  beforeDestroy() { },

  // 生命周期 - 销毁完成
  destroyed() { },

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() { },

  // keep-alive缓存离开之后触发
  deactivated() { },
};
</script>

<style scoped>
.content{
  margin:10px 10px;
  background:#f2f7fd;
  padding:10px 20px;
  height:500px;
  /* overflow: hidden; */
  overflow-y:scroll
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.1);
  background-color: #c8c8c8;
}
.content p{
  font-size:14px
}
.happy{
  ::v-deep .el-dialog__body{
    padding: 0;
  }
  ::v-deep .el-dialog__header{
    padding: 0;
  }
}
.happy >>> .el-dialog__body {
  padding: 0;
}

.happy >>> .el-dialog__header {
  padding: 0;
}
.login {
  height: 100%;
  background-image: url("../assets/f30edb24867d75bbfecd13d37e87658.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.vessel {
  width: 986px;
  height: 519px;
  background: #ffffff;
  opacity: 0.84;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 120px;
  display: flex;
}
.leftvessel {
  width: 255px;
  height: 513px;
  background-image: url("../assets/80e92be8e02a405b6827820e220dfd3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 3px;
  margin-left: 3px;
}
.leftbutton {
  height: 58px;
  width: 150px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  background-image: url("../assets/圆角矩形 3@2x.png");
  background-size: 160px 58px;
  /* text-align: center; */
  padding-left: 10px;
  line-height: 48px;
  margin-top: 5px;
  cursor: pointer;
}
.demo-ruleForm {
  width: 100%;
}
.account {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #039eee;
}
.CAlogin {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #039eee;
}
.CFCAlogin {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #039eee;
}
a {
  text-decoration: none;
  color: #333;
}

a:hover,
a:visited,
a:link,
a:active {
  color: #fff;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-top: 0;
}
.topTitle {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  width: 500px;
  cursor: pointer;
  margin: 43px auto 0px;
  border-bottom: 1px solid rgb(230, 230, 230);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.befor_1 {
  position: absolute;
  left: -64px;
  color: #f56c6c;
}
.befor_2 {
  position: absolute;
  left: -92px;
  color: #f56c6c;
}
.befor_3 {
  position: absolute;
  left: -64px;
  color: #f56c6c;
}
.befor_4 {
  position: absolute;
  left: 0px;
  color: #f56c6c;
}
.demo-enrollForm >>> .el-form-item {
  margin-bottom: 19px;
}
/deep/ .el-message-box{
  width:600px !important;
}
</style>